import React from "react";
import "../projects.css";
import Project from "./Project";

export default function Projects() {
    const projects = [
        {
            name: "Foodi",
            technologies:
                "React | NodeJS | CSS/SASS | JSON | Express | Git | Heroku",
            photo: "foodi",
            link: "https://foodiapp.herokuapp.com/"
        },
        {
            name: "Celeste Boutique",
            technologies:
                "React | NodeJS | Material-Ui | CSS | JSON | Netlify | Git",
            photo: "celeste-baby-boutique",
            link: "https://jessicadelcy-celeste-baby-boutique.netlify.app/"
        },
        {
            name: "Counter App",
            technologies: "React | NodeJS | Material-Ui| CSS | Netlify | Git",
            photo: "counter",
            link: "https://jessicadelcy-react-counter.netlify.app/"
        },
        {
            name: "To-Do List",
            technologies: "React | NodeJS | Material-Ui | CSS | Netlify | Git",
            photo: "to-do",
            link: "https://jessicadelcy-react-todo.netlify.app/"
        },
        {
            name: "Connect Four",
            technologies: "React | Java | SpringBoot | CSS | JSON | Git",
            photo: "connect-four"
        },
        {
            name: "Loan Calculator",
            technologies: "Vanilla JavaScript | Bootstrap",
            photo: "loan-calculator",
            link: "https://jessicadelcy-loancalculator.netlify.app/"
        }
    ];

    return (
        <div>
            <div style={{ paddingTop: "2rem" }}>
                <h1>Projects / Gallery</h1>
            </div>
            <div class="albums">
                {projects.map(project => (
                    <Project project={project} />
                ))}
            </div>
        </div>
    );
}
