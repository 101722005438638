import React from "react";
import "../projects.css";

export default function Project(props) {
    const { project } = props;
    return (
        <div className="container">
            <a href={project.link} target="_blank">
                <img
                    src={require(`../assets/images/projects/${project.photo}.png`)}
                    alt=""
                />
                <div class="overlay">
                    <div class="text">
                        <h3>{project.name}</h3>
                        <p>{project.technologies}</p>
                    </div>
                </div>
            </a>
        </div>
    );
}
