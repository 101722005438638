import React from "react";
import "../contact.css";

export default function Contact() {
    return (
        <div>
            <div>
                <main>
                    <section>
                        <h1>Send me a message!</h1>
                    </section>
                    <div class="form">
                        <form>
                            <div class="input-fields">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    id=""
                                    required
                                />
                                <input type="text" placeholder="Phone Number" />
                            </div>
                            <div class="msg">
                                <textarea
                                    name="message"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    placeholder="Write your message here..."
                                ></textarea>
                            </div>
                            <div>
                                <button>Send Message</button>
                            </div>
                        </form>
                    </div>

                    <section class="contact-icons">
                        <a
                            href="https://www.linkedin.com/in/jessicaulysse/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="tel:+9722659819">
                            <i class="fas fa-phone"></i>
                        </a>
                        <a href="mailto:sendto@JessicaDelcy.com">
                            <i class="fas fa-envelope"></i>
                        </a>
                        <a
                            href="https://github.com/PrettyScript"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i class="fab fa-github"></i>
                        </a>
                    </section>
                </main>
            </div>
        </div>
    );
}
