import React from "react";
import "../home.css";

export default function Home() {
    return (
        <div className="bg-img">
            <div>
                <h2 className="title-name">Jessica Ulysse</h2>
                <div id="section">
                    <div>
                        <img
                            className="profile-img"
                            src={require("../../src/assets/images/jess.JPG")}
                            alt=""
                        />
                    </div>
                    <div className="flex">
                        <div className="summary">
                            <h2>Summary</h2>
                            <p>
                                Hi, my name is Jessica Ulysse. I was born and
                                raised in Miami, FL. I love spending time with
                                my family. I'd like to think of myself as the
                                R&B connoisseur, as well as a lover of food(all
                                types, I'm not picky).
                            </p>
                        </div>

                        <div className="hobbies">
                            <h2>Hobbies:</h2>
                            <ul>
                                <li>Listening to music</li>
                                <li>Watching True Crime Documentaries</li>
                                <li>Scrolling between Twitter & Instagram</li>
                                <li>Playing video games</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
